import React from 'react'
import '../css/App.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Contact = () => {

    return(
        <section className="grid">
            <h2>Contact</h2>
            <div id="blurb"><p>Let's get in touch! If you've got questions or would like to hear about how we can get your business organized, please contact me.</p></div>
            <div>
                <ul id="contactSectionLinks">
                    <li><a href="tel:905-334-0252"><FontAwesomeIcon icon="phone-square-alt" />905-334-0252</a></li>
                    <li><a href="mailto:yvonne@yourbooksbalanced.ca"><FontAwesomeIcon icon="envelope-square" />yvonne@yourbooksbalanced.ca</a></li>
                    <li><a href="https://www.linkedin.com/in/yvonne-robson-615095108/"><FontAwesomeIcon icon={['fab', 'linkedin']} />Yvonne Robson</a></li>
                </ul>
            </div>
        </section> 
    )
    }
export default Contact