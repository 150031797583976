import React from 'react'
import '../css/App.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import logo from './../img/logo.png'
import Contact from './../components/Contact'
import profile from "./../img/0O7C8932.jpg"
require('../img/andrej-lisakov-3A4XZUopCJA-unsplash.jpg')

const Home = () => {

	function contactScroll(){
		document.getElementById("landingContact").scrollIntoView();
	}

	return(
		<main>
			<section id="welcomeHero">
				<img src={logo} />
				<h1>Helping You to Grow Your Business</h1>
				<button onClick={contactScroll}> Contact Us!</button>
				<div><FontAwesomeIcon icon="chevron-down" /></div>
				
			</section>
			<section id="about" className="grid">
				<h2>Hi, I'm Yvonne</h2>
				<div><img src={profile}/></div>
				<div><p>I have been in the accounting field since high school in 1985. I have worked in the banking industry and
then as managing bookkeeper for a luxury and specialty vehicle leasing company during the 90’s. </p>
					<p>I began my self employment journey in
1996; after taking a break to be a stay-at-home mom to my girls – it was time to get back to my need for
numbers. Since then, I’ve been running my own business. I specialize in working with small business
owners and entrepreneurs.</p></div>
			</section>
			<div id="landingContact">
			<Contact />
			</div>
		</main>
	)
}

export default Home