import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Header from './components/Header';
import Footer from './components/Footer';
import Resources from './components/Resources';
import Services from './components/Services';
import Home from './components/Home';
import Error404 from './components/Error404';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faArrowDown, faEnvelopeSquare, faPhoneSquareAlt, faChevronDown} from '@fortawesome/free-solid-svg-icons';
require('./img/favicon-03.svg')
library.add(faPhoneSquareAlt, faEnvelopeSquare, faLinkedin, faArrowDown, faChevronDown)


// This site has 3 pages, all of which are rendered
// dynamically in the browser (not server rendered).
//
// Although the page does not ever refresh, notice how
// React Router keeps the URL up to date as you navigate
// through the site. This preserves the browser history,
// making sure things like the back button and bookmarks
// work properly.

export default function BasicExample() {
  return (
    <Router>
      <Header />
      <div>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/services">
            <Services />
          </Route> 
          <Route path="/resources">
            <Resources />
          </Route>             
          <Route path="*">
            <Error404 />
          </Route>
        </Switch>
      </div>
      <Footer />
    </Router>
  );
}

// You can think of these components as "pages"
// in your app.

