import React from 'react'
import '../css/App.css'

const Resources = () => {

    window.scrollTo(0,0);

    return (
        <main>
            <section id="resourceHero">
                <h1>Resources</h1>
                <p>Feel free to bookmark this page to keep a catalog of useful links used regularily by Canadian small businesses.</p>
            </section>
            <section id="resourceGrid" className="grid">
                <div>
                    <h2>GOVERNMENT OF CANADA</h2>
                    <ul>
                        <li><a href="https://www.ic.gc.ca/eic/site/cd-dgc.nsf/eng/cs06642.html">How to Incorporate a Business</a></li>
                        <li><a href="https://www.canada.ca/en/services/taxes/resources-for-small-and-medium-businesses/business-number-registration.html">Business Number Registration</a></li>
                        <li><a href="https://www.canada.ca/en/services/taxes/resources-for-small-and-medium-businesses/gst-hst.html">GST/HST</a></li>
                        <li><a href="https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/payroll.html">Steps for Payroll</a></li>
                        <li><a href="https://www.canada.ca/en/revenue-agency/services/e-services/e-services-businesses/business-account.html">Canada.ca's My Business Account</a></li>
                        <li><a href="https://www.canada.ca/en/revenue-agency/services/payments-cra.html">Payments to the CRA for Individuals & Businesses</a></li>
                    </ul>
                </div>
                <div>
                    <h2>COVID-19 RELATED SITES</h2>
                    <ul>
                        <li><a href="https://www.canada.ca/en/revenue-agency/services/benefits/recovery-benefit.html">Canada Recovery Benefit (CRB)</a></li>
                        <li><a href="https://www.canada.ca/en/revenue-agency/services/subsidy/emergency-wage-subsidy.html">Canada Emergency Wage Subsidy (CEWS)</a></li>
                        <li><a href="https://www.canada.ca/en/revenue-agency/services/subsidy/emergency-rent-subsidy.html">Canada Emergency Rent Subsidy (CERS)</a></li>
                    </ul>
                </div>
                <div>
                    <h2>ONLINE TOOLS</h2>
                    <ul>
                        <li><a href="https://quickbooks.intuit.com/ca/">Quickbooks</a></li>
                        <li><a href="https://quickbooks.intuit.com/ca/time-tracking/">Quickbooks Time (Formerly TSHEETS)</a></li>
                        <li><a href="https://www.hubdoc.com/">Document Storage by Hubdoc</a></li>
                    </ul>
                </div>
                <div>
                    <h2>MORE USEFUL LINKS</h2>
                    <ul>
                        <li><a href="https://www.bizpal.ca/">Regional Business Permit and Licence Information</a></li>
                        <li><a href="https://www.wsib.ca/en">WSIB Ontario</a></li>
                        <li><a href="https://www.fin.gov.on.ca/en/tax/eht/index.html">Employer Health Tax (EHT)</a></li>
                    </ul>
                </div>
            </section>
            <hr />
        </main>
    )
}
export default Resources