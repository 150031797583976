import React from 'react'
import '../css/App.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import logoHead from "../img/favicon.jpg"

const Header = () => {
    return(
        <nav>
            <ul className="topnav">
                <li className="left"><a href="/"><img src={logoHead}/></a></li>
                <li className="navList" id="resLink"><a href="/resources">Resources</a></li>
                <li className="navList" id="servLink"><a href="/services">Services</a></li>
                <li>
                <div className="dropdown">
                <button className="dropbtn">
                <FontAwesomeIcon icon="arrow-down" />
                </button>
                    <div className="dropdown-content">
                    <a href="resources">Resources</a>
                    <a href="services">Services</a>
                    </div>
                </div>
                </li>
                <li>
                    <div className="grid" id="navcontacts">
                    <div><a href="tel:905-334-0252"><FontAwesomeIcon icon="phone-square-alt" /></a></div>
                    <div><a href="mailto:yvonne@yourbooksbalanced.ca"><FontAwesomeIcon icon="envelope-square" /></a></div>
                    <div><a href="https://www.linkedin.com/in/yvonne-robson-615095108/" target="_blank"><FontAwesomeIcon icon={['fab', 'linkedin']} /></a></div>
                    </div>
                </li>
            </ul>
        </nav>
    )
}

export default Header