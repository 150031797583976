import React from 'react'
import '../css/App.css'

const Footer = () => {
    return (
        <section id="footer" className="grid">
            <div>
                <ul>
                    <li><a href="/#">Home</a></li>
                    <li><a href="/services">Services</a></li>
                    <li><a href="/resources">Resources</a></li>
                </ul>
            </div>
            <div>
                <p>This site is property of Your Books Balanced, 2021, made by <a href="https://www.honeycombdd.com">Honeycomb Design & Development</a>.</p>
            </div>
        </section>
    )
}
export default Footer 
