import React from 'react'
import '../css/App.css'
import Contact from '../components/Contact'

const Services = () => {
    return (
        <main>
            <section id="serviceHero">
                <h1>Services</h1>
            </section>
            <section id="serviceList">
                    <h2>Some of what I do:</h2>
                    <ul>
                        <li>Coding income and expenses</li>
                        <li>Managing Payables & Receivables</li>
                        <li>Receipt Management</li>
                        <li>Monthly Reconciliations</li>
                        <li>Monthly Prepared Financial Statements</li>
                        <li>Monthly Consulting Services</li>
                    </ul>
                    <hr />
            </section>
            <section id="hireGrid" className="grid">
                    <h2>Why hire an online bookkeeper?</h2>
                    <div>
                        <h3>Efficiency</h3>
                        <p>Your time is best spent doing what you know how to do – your expertise! Online bookkeeping stays on top of your books even faster because that is our expertise. Online bookkeepers allow you to spend more time on your business making money and growing your business.</p>
                        <p>By having an online bookkeeper, they will reduce errors &amp; save the business owner from learning about the advanced aspects of bookkeeping, all the while looking for tax savings opportunities.</p>
                        <p>One challenge to all business owners is invoicing, because there is always so much else that needs to be done. Online bookkeepers know how critical invoicing is to your business, and your cash flow; therefore, it must be done correctly and on time. Need assistance in collecting delinquent accounts? An online bookkeeper can also help you in that area.</p>
                    </div>
                    <div>
                        <h3>Lower Costs</h3>
                        <p>Having a virtual bookkeeper saves you payroll and its associated costs – employee benefits, insurance, training, operating and facility costs (desk space, office supplies); this gives you the flexibility to add more staff to support the core of your business (sales persons or crew members).</p>
                        <p>Outsourcing your bookkeeping to a bookkeeper rather than an accountant’s office allows you to save money monthly since a bookkeeper is generally paid less than an accountant’s office for the same work. Your monthly costs are the online software that is used and bookkeeper’s fee. The bookkeeper hands the books over to your accountant for final year end review and financial statements. This allows you to be fully prepared for tax season, with minimal work needed by the accountant.</p>
                        <p>Your bookkeeper will also assist with reminders of due dates for government filings which will also reduce late payments and stress. They will also ensure you will be completely organized in that all documentation and or receipts have been attached to your online records.</p>
                    </div>
                    <div>
                        <h3>Productivity</h3>
                        <p>Time is saved with automation. Bank account and credit card feeds download automatically, for quicker accounting practices. Automation allows for the tracking of income and expenses with little effort. You can leverage technology by use of online software which leads to a reduction of paperwork. The virtual bookkeeper then uses that same technology to keep accurate records for you.</p>
                    </div>
            </section>
            <div id="quickbooks">
                        <h3>A Word About Online Bookkeeping Software – QuickBooks Online</h3>
                        <p>Your data is backed up constantly. Your data is always accessible by authorized personnel, at a minimum this would include yourself, your accountant and your bookkeeper.</p>
                        <p>You always have real-time, accurate financial reporting at your fingertips from a secure Internet connection. You can see historical financial data which can help with financial forecasting and planning and make smart business decisions. Your financial data has supporting documentation with each entry to aid if called for in a CRA audit.</p>
                    </div>
            <section id="steps">
                <h2>Ready to get started?</h2>
                <ul>
                    <li>
                        <h3>Step 1 Consultation:</h3>
                        <p>We will meet by video chat or by phone to discuss your business and specifically the needs you have for bookkeeping.</p>
                    </li>
                    <li>
                        <h3>Step 2 Onboarding:</h3>
                        <p>Assistance if necessary to get you set up on QuickBooks Online, QuickBooks Time Tracker, Hubdoc, Google Drive;  All necessary documents and information will be requested, so we can get you organized and up to date.</p>
                    </li>
                    <li>
                        <h3>Step 3 Services & Monthly Reporting:</h3>
                        <p>We handle all of your bookkeeping and you’ll receive a monthly summary email from us.</p>
                    </li>
                </ul>
            </section>
            <section id="servCall">
                <p>Are you nervous about reaching out? Please remember that everything is confidential. If your business is experiencing a difficult season, we can help by cleaning up your financials so that you can fully understand what part of your business needs improvement or attention.</p>
                <p>Still nervous? Reach out to me by email or by text and we’ll set up a time for a quick call.</p>
            </section>
            <div id="servContact">
            <Contact />
            </div>
        </main>
    )
}
export default Services