import React from 'react'
import '../css/App.css'

const Error404 = () => {
    return(
        <>
            <h1>Looks like that page doesn't exist, sorry.</h1>
        </>
    )
}

export default Error404